import React, { useState, useEffect } from 'react';
import './navbar.css'; // Import the Navbar CSS file

const Navbar = () => {
  const [animate, setAnimate] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div id="header" className={animate ? 'fade-in' : ''}>
      <div className="container">
        <nav>
          <a href="/" className="logo">Adam Georghiou</a>
          <div className={`nav-container ${menuOpen ? 'active' : ''}`}>
            <ul className={`nav-links ${menuOpen ? 'active' : ''}`} id="sidemenu">
              <li><a href="#home">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#education">Education</a></li>
              <li><a href="#resume">Resume</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
            <i className="fas fa-bars nav-toggle" onClick={toggleMenu}></i>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
