import React, { useEffect, useState } from 'react';
import { ReactTyped } from 'react-typed';
import './hero.css';

const Hero = () => {
  useEffect(() => {
    fetch('/images/finalsvg.svg')
      .then(response => response.text())
      .then(data => {
        document.getElementById('hero-svg').innerHTML = data;
      });
  }, []);

  return (
    <main id="home">
      <section className="hero">
        <div className="hero-content">
          <h1>
            Hello <span className="wave">👋</span>
          </h1>
          <div className="typed-container">
            <div className="typed-text">
              <ReactTyped
                strings={[
                  "I'm <span class='highlight'>Adam Georghiou</span>, a Software Engineer 💻<br/>Passionate About Continuous Growth And Development."
                ]}
                typeSpeed={60}
                backSpeed={0}
                loop={false}
                showCursor={false}
              />
            </div>
          </div>
          <a href="#contact" className="contact-button">Contact Me</a>
        </div>
        <div id="hero-svg" className="hero-image"></div>
      </section>
    </main>
  );
};

export default Hero;
