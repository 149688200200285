import React from 'react';
import './resume.css';

const Resume = () => {
  return (
    <section className="resume">
      <h2>Resume</h2>
      <div className="resume-section">
        <h3>Professional Profile</h3>
        <p>Computer Science graduate from Newcastle University. I specialise in software development with a strong emphasis on becoming proficient in cloud computing, machine learning, and data engineering. I am committed to leveraging my analytical skills and technical expertise to solve complex problems in data-driven environments. Experienced with AWS, Python, and scalable data architectures, I am eager to learn and apply my skills in innovative projects that require robust data analysis and cloud infrastructure management.</p>
      </div>
      <div className="resume-section">
        <h3>Education</h3>
        <p><strong>Newcastle University</strong> | Bachelor of Science (Hons) Computer Science 2:1</p>
        <p>Sept 2020 - June 2023</p>
        <p>Dissertation: Designing an iOS application which aids people who struggle with general wellbeing to develop meditation habits</p>
        <p>Specialised Modules: Predictive Analytics, Computer Vision & AI, Data Visualisation and Visual Analytics, Cryptography, Human Computer Interaction</p>
      </div>
      <div className="resume-section">
        <h3>Experience</h3>
        <p><strong>Bentley Motors</strong> | Finance and Marketing Internship</p>
        <p>August 2018</p>
        <ul>
          <li>I shadowed the global head of product development and collaborated closely with the product development team.</li>
          <li>Conducted in-depth market research to analyse competitors and deliver a comprehensive presentation on industry competition.</li>
          <li>Gained team collaboration and communication skills in a fast-paced office environment.</li>
        </ul>
      </div>
      <div className="resume-section">
        <h3>Software Projects</h3>
        <ul>
          <li><strong>Android App Development Team Project</strong>: Learned android development language Kotlin. Deepened understanding of Agile methodologies. Gained practical experience in technical documentation and collaborative software project management.</li>
          <li><strong>House price prediction using machine learning</strong>: Developed skills in data preparation and engineering implementing machine learning prediction models.</li>
          <li><strong>Convolutional neural network image classification</strong>: Constructed and refined CNN architectures using Keras and TensorFlow focusing on VGGNet and ResNet models.</li>
          <li><strong>Candlestick Chart Generation</strong>: Created a script to visualise financial data as candlestick charts using Matplotlib and Seaborn.</li>
          <li><strong>Meditation Application Design</strong>: Conducted ethnography study from HCI principles to create functional/non-functional requirements. Used 5 stage design process to create a user-friendly design. Created high fidelity prototype using Figma then conducted user testing.</li>
        </ul>
      </div>
      <div className="resume-section">
        <h3>Skills</h3>
        <p><strong>Technical</strong>: Python, Pandas, Matplotlib, NumPy, MySQL, HTML, CSS, Git, Figma, JavaScript, Scikit-Learn, Seaborn, TensorFlow</p>
        <p><strong>Certifications</strong>: AWS Certified Cloud Practitioner</p>
        <p><strong>Soft</strong>: Excellent written and verbal communication, adept at presentations, flexible and cooperative team member, highly driven and skilled at problem-solving.</p>
      </div>
      <div className="resume-section">
        <h3>Hobbies</h3>
        <p>I participate socially in football 5-a-side and practice martial arts including Brazilian jiu-jitsu and boxing. I am committed to health and do regular exercise sessions with friends. I'm passionate about creative projects like helping family and friends establish websites for their businesses. Additionally, I like hiking, reading, and making music in my leisure time.</p>
      </div>
    </section>
  );
};

export default Resume;
