import React from 'react';
import './contact.css';

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Me</h2>
      <p>You can contact me at the places mentioned below. I will try to get back to you as fast as I can.</p>
      <div className="contact-icons">
        <a href="https://github.com/AdamGeorghiou" target="_blank" rel="noopener noreferrer">
          <img src="/images/github.png" alt="GitHub" />
        </a>
        <a href="https://www.linkedin.com/in/adam-georghiou-09b54a215/" target="_blank" rel="noopener noreferrer">
          <img src="/images/linkedin.png" alt="LinkedIn" />
        </a>
        <a href="mailto:aaageorghiou@gmail.com">
          <img src="/images/mail.png" alt="Email" />
        </a>
      </div>
    </section>
  );
};

export default Contact;
