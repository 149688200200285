import React, { useEffect, useRef } from 'react';
import './about.css';

const About = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = aboutRef.current;
      const aboutPosition = aboutSection.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;

      if (aboutPosition < screenPosition) {
        aboutSection.querySelectorAll('.animate-on-scroll').forEach((el) => {
          el.classList.add('animate');
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="about" className="about" ref={aboutRef}>
      <h2 className="animate-on-scroll">About Me</h2>
      <div className="about-container">
        <div className="about-svg animate-on-scroll">
          <img src="/images/aboutsvg.svg" alt="About SVG" />
        </div>
        <div className="about-content animate-on-scroll">
          <h3 className="animate-on-scroll">Developer Skills</h3>
          <ul className="skills-list animate-on-scroll">
            <li>Design and developing interactive Front end / User Interfaces for your web applications using HCI principles</li>
            <li>AWS Certified Cloud Practitioner with knowledge in deploying and managing cloud infrastructure</li>
            <li>Experienced in Agile project management and collaborative software development</li>

            <li>Experience implementing ML models for predictive analytics and developing visualization tools using Matplotlib, Seaborn, and TensorFlow</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
