import React from 'react';
import './app.css';
import Hero from './components/hero';
import Navbar from './components/NavBar';
import About from './components/About';
import Education from './components/Education';
import Resume from './components/resume'
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Education />
      <Resume />
      <Contact />
    </div>
  );
}

export default App;
