// src/components/Education.js

import React, { useEffect, useRef } from 'react';
import './education.css';

const Education = () => {
  const educationRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const educationSection = educationRef.current;
      const educationPosition = educationSection.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;

      if (educationPosition < screenPosition) {
        educationSection.querySelectorAll('.animate-on-scroll').forEach((el) => {
          el.classList.add('animate');
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="education" className="education" ref={educationRef}>
      <h2 className="animate-on-scroll">Education</h2>
      <div className="education-container">
        <div className="education-content animate-on-scroll">
          
          <ul className="degrees animate-on-scroll">
            <li className="degree">
              <div className="degree-content">
                <h4>Bachelor of Science in Computer Science</h4>
                <p>Newcastle University</p>
                <span>2020 - 2023</span>
                <ul>
                  <li>Studied core subjects like Data Structures, DBMS, Networking, Cryptography, Design.</li>
                  <li>Completed various online courses for Backend, Web, Mobile App Development.</li>
                  <li>Implemented several projects based on what was learned under the course.</li>
                </ul>
              </div>
              <div className="degree-icon">
                <img src="/images/Newcastleicon.png" alt="Institution Logo" />
              </div>
            </li>
            <li className="degree">
              <div className="degree-content">
                <h4>AWS Certified Cloud Practitioner</h4>
                <p>Amazon Web Services</p>
                <span>2024</span>
                <ul>
                  <li>Knowledge in deploying and managing cloud infrastructure.</li>
                  <li>Experience with various AWS services and tools.</li>
                </ul>
              </div>
              <div className="degree-icon">
                <img src="/images/aws.png" alt="Institution Logo" />
              </div>
            </li>
            <li className="degree">
              <div className="degree-content">
                <h4>Cheadle Hulme School</h4>
                <p>A-level</p>
                <span>2021</span>
                <ul>
                  <li>Achieved high grades in Mathematics, Economics, and Business and Management.</li>
                  <li>Active participant in extracurricular activities.</li>
                </ul>
              </div>
              <div className="degree-icon">
                <img src="/images/schoollogo.png" alt="Institution Logo" />
              </div>
            </li>
          </ul>
        </div>
        <div className="education-svg animate-on-scroll">
          <img src="/images/educationsvg.svg" alt="Education SVG" />
        </div>
      </div>
    </section>
  );
};

export default Education;
